<template>
	<v-container fluid pa-0>
		<FilesPreview v-if="previewFile"></FilesPreview>
		<v-row v-show="!previewFile" no-gutters>
			<v-col cols="12" sm="3" class="content-col-container mb-4 h-100">
				<FilesFilters />
			</v-col>
			<v-col cols="12" sm="9" class="content-col-container">
				<FilesList />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import FilesFilters from '@/components/FilesFilters';
import FilesList from '@/components/FilesList';
import FilesPreview from '@/components/FilesPreview';

const { mapState } = require('vuex');

export default {
	name: 'ContentLibrary',

	components: {
		FilesFilters,
		FilesList,
		FilesPreview
	},

	computed: {
		...mapState({
			previewFile: (state) => state.contentLibrary.previewFile
		})
	}
};
</script>
