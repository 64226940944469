<template>

	<div class="preview">
		<iframe title="PDF preview" :src="previewURL" v-show="$vuetify.breakpoint.mdAndDown === false" />
		<div class="image" v-show="$vuetify.breakpoint.mdAndDown === true">
			<img :src="previewURL" alt="preview" />
		</div>
	</div>

</template>

<script>

export default {
	props: {
		previewURL: {
			type: String,
			default: null
		}
	}
};

</script>

<style lang="scss" scoped>

.preview {
	padding: 0 !important;
	margin: 0 !important;
	height: calc(100vh - 140px);
	overflow: hidden;
	iframe {
		width: 100%;
		height: 100%;
		border: none;
	}
	.image {
		background-color: #000;
		padding: 0 32px;
		height: 100%;
    overflow: auto;
		img {
			max-width: 100%;
		}
	}
}

</style>
