<template>
	<div class="filter-group pb-4">
		<v-row class="ma-0 pa-0">
			<v-col cols="12" class="ma-0 pa-0">
				<FilterGroupHeader
					label="Filter Documents"
					filtersGuide="FilesFiltersGuide"
					:selectedFilters="selectedFilters"
					@clearAll="onClearFilters"
					@clearFilter="unselectFilter"
				></FilterGroupHeader>
			</v-col>
			<v-col cols="12" class="ma-0 pa-0">
				<OneGroupSelect
					label="Non Survey"
					:value="selectedNonSurveyRespondentTypes"
					@input="setSelectedNonSurveyRespondentTypes($event)"
					:items="nonSurveyRespondentTypes"
					:activeItems="activeNonSurveyRespondentTypes"
				></OneGroupSelect>
			</v-col>
			<v-col cols="12" class="ma-0 pa-0">
				<OneGroupSelect
					label="Survey"
					:value="selectedSurveyRespondentTypes"
					@input="setSelectedSurveyRespondentTypes($event)"
					:items="surveyRespondentTypes"
					:activeItems="activeSurveyRespondentTypes"
				></OneGroupSelect>
			</v-col>
			<v-col cols="12" class="ma-0 pa-0">
				<OneGroupSelect
					label="Market"
					:value="selectedMarkets"
					@input="setSelectedMarkets($event)"
					:items="markets"
					:activeItems="activeMarkets"
					:synonyms="marketSynonyms"
				></OneGroupSelect>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import FilterGroupHeader from '@/components/FilterGroupHeader';
import OneGroupSelect from '@/components/OneGroupSelect';

const { mapState, mapGetters, mapMutations, mapActions } = require('vuex');
const { removeItemFromLists }= require('@/utils/filter');

export default {
	name: 'FilesFilters',

	components: {
		FilterGroupHeader,
		OneGroupSelect
	},

	computed: {
		...mapState({
			markets: (state) => state.contentLibrary.markets,
			selectedMarkets: (state) => state.contentLibrary.selectedMarkets,

			nonSurveyRespondentTypes: (state) => state.contentLibrary.nonSurveyRespondentTypes,
			selectedNonSurveyRespondentTypes: (state) => state.contentLibrary.selectedNonSurveyRespondentTypes,

			surveyRespondentTypes: (state) => state.contentLibrary.surveyRespondentTypes,
			selectedSurveyRespondentTypes: (state) => state.contentLibrary.selectedSurveyRespondentTypes
		}),
		...mapGetters('contentLibrary', [
			'activeMarkets',
			'activeNonSurveyRespondentTypes',
			'activeSurveyRespondentTypes'
		]),
		...mapGetters('general', [
			'marketSynonyms'
		]),
		selectedFilters () {
			return [...this.selectedMarkets, ...this.selectedNonSurveyRespondentTypes, ...this.selectedSurveyRespondentTypes];
		}
	},

	methods: {
		...mapMutations('contentLibrary', [
			'setSelectedMarkets',
			'setSelectedNonSurveyRespondentTypes',
			'setSelectedSurveyRespondentTypes'
		]),
		...mapActions('contentLibrary', [
			'loadFiltersForFiles'
		]),
		...mapActions('general', [
			'loadSearchData'
		]),
		unselectFilter (id) {
			const updatedFilters = removeItemFromLists(id, {
				markets: this.selectedMarkets,
				nonSurveyRespondentTypes: this.selectedNonSurveyRespondentTypes,
				surveyRespondentTypes: this.selectedSurveyRespondentTypes
			});
			this.setSelectedMarkets(updatedFilters.markets);
			this.setSelectedNonSurveyRespondentTypes(updatedFilters.nonSurveyRespondentTypes);
			this.setSelectedSurveyRespondentTypes(updatedFilters.surveyRespondentTypes);
		},
		onClearFilters () {
			this.initSelectedFilters();
		},
		initSelectedFilters () {
			this.setSelectedMarkets([]);
			this.setSelectedNonSurveyRespondentTypes([]);
			this.setSelectedSurveyRespondentTypes([]);
		}
	},

	async beforeMount () {
		if (!this.surveyRespondentTypes.length) {
			await this.loadFiltersForFiles();
		}
		this.initSelectedFilters();

		if (!this.marketSynonyms.length) {
			this.loadSearchData();
		}
	}
};
</script>
