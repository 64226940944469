<template>
	<nav class="pdf-preview-nav">
		<div class="_container">
			<div class="actions">
				<span class="documents-actions" v-if="list.length">
					{{ currentIndex + 1 }} of {{ list.length }} documents
					<v-btn icon x-small @click="previousDocument" :ripple="false"
						:class="{ _disabled: currentIndex === 0 }">
						<i class="icon-UI-experience-arrow-previous" aria-hidden="true"></i>
					</v-btn>
					<v-btn icon x-small @click="nextDocument" :ripple="false"
						:class="{ _disabled: currentIndex === list.length - 1 }">
						<i class="icon-UI-experience-arrow-next" aria-hidden="true"></i>
					</v-btn>
				</span>
				<span @click="$emit('close')" class="material-icons-outlined">
					close
				</span>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: 'PDFPreviewNav',

	props: {
		file: {
			type: Object || null,
			default: null
		},
		list: {
			type: Array,
			default: () => []
		}
	},

	computed: {
		currentIndex () {
			return this.list.findIndex((f) => f.key === this.file.key);
		}
	},

	methods: {
		previousDocument () {
			if (this.currentIndex === 0) return;
			const previousFile = this.list[this.currentIndex - 1];
			this.$emit('input', previousFile);
		},
		nextDocument () {
			if (this.currentIndex > this.list.length - 2) return;
			const nextFile = this.list[this.currentIndex + 1];
			this.$emit('input', nextFile);
		}
	}
};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";

.pdf-preview-nav {
  padding: 8px 0;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  background-color: $blue-100;

  ._container {
    display: flex;
    align-items: center;

    .actions {
      margin-left: auto;

      button {
        width: auto !important;
        height: auto !important;
      }

      .v-btn__content {
        background-color: $blue-100;
        i[class^="icon"] {
          color: $blue-600;
          font-size: 28px;
          border-width: 1.5px;
          border-style: solid;
          border-color: $blue-600;
          border-radius: 50%;
          display: flex;
          width: 28px;
          height: 28px;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          i[class^="icon"] {
            color: $blue-100;
            border-color: $blue-600;
            background-color: $blue-600;
          }
        }
      }

      .documents-actions {
        font-size: 12px;
        font-weight: 500;
        color: $meta-blue;

        button {
          margin-left: 8px;

          &._disabled {
            cursor: default;

            i {
              color: #CCD2D8 !important;
              border-color: #CCD2D8 !important;
            }

            .v-btn__content:hover {
              i {
                color: #CCD2D8 !important;
                border-color: #CCD2D8 !important;
                background-color: $blue-100 !important;
              }
            }
          }
        }

        &:after {
          content: "";
          display: inline-block;
          width: 0;
          height: 16px;
          border-left: 1px dashed $meta-blue;
          vertical-align: middle;
          margin: 0 16px;
        }
      }

      .material-icons-outlined {
        vertical-align: middle;
        color: $meta-blue;
        cursor: pointer;
      }
    }
  }
}
</style>
