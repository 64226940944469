<template>
	<v-row no-gutters>
		<v-col class="content-col-container">
			<PDFPreviewNav
				:file="previewFile"
				:list="pdfFiles"
				@input="setPreviewFile"
				@close="setPreviewFile(null)"
			/>
			<PDFPreview
				:previewURL="fileURL"
				class="ma-n3"
			/>
		</v-col>
	</v-row>
</template>

<script>
import PDFPreviewNav from '@/components/PDFPreviewNav';
import PDFPreview from '@/components/PDFPreview';

const { mapState, mapGetters, mapMutations } = require('vuex');

export default {
	name: 'FilesPreview',

	components: {
		PDFPreviewNav,
		PDFPreview
	},

	computed: {
		...mapState({
			previewFile: (state) => state.contentLibrary.previewFile
		}),
		...mapGetters('contentLibrary', [
			'filteredFiles'
		]),
		fileURL () {
			return `/api/v1/static-file/download?fileKey=${this.previewFile.key}#toolbar=1`;
		},
		pdfFiles () {
			return this.filteredFiles.filter((f) => f.extension === 'PDF');
		}
	},

	methods: {
		...mapMutations('contentLibrary', [
			'setPreviewFile'
		])
	}
};
</script>

