<template>
	<div class="files-list px-5 py-0">
		<v-container class="flex-column">
			<v-row class="files-list-actions pa-2">
				<v-text-field
					class="files-list-actions-search"
					label="Search"
					:value="search"
					@input="setSearch"
					prepend-inner-icon="search"
					color="metaGray"
					autocomplete="off"
					clearable
				>
					<v-icon slot="prepend-inner" color="metaGray">
						search
					</v-icon>
				</v-text-field>
				<v-spacer></v-spacer>
				<span
					v-if="!loadingFiles"
					class="files-list-actions-title mr-6"
				>
					All Documents - {{ filteredAndSortedFiles.length || 0 }} of {{ files.length || 0 }}
				</span>
				<FiltersButtonList
					v-model="sort.value"
					:items="sort.items"
					@input="onChangeSort"
				/>
			</v-row>
			<v-row>
				<v-col
					v-for="(file, key) in filteredAndSortedFiles"
					:key="key"
					cols="12"
					sm="6"
					md="4"
					lg="3"
					class="pa-2"
				>
					<FileCard
						:loading="loadingFiles"
						:file="file"
						fileUrl="/api/v1/methodology-collateral/thumbnail?thumbnailKey="
						@preview="onSelectPreview(file)"
						@download="onSelectExportFromContentLibrary(file)"
					/>
				</v-col>

				<v-col v-if="!!files.length && !filteredAndSortedFiles.length" cols="12" sm="6" md="4" lg="3" class="pa-2">
					<EmptyCard text="No Content Available" />
				</v-col>

			</v-row>
		</v-container>

		<v-snackbar v-model="showDownloadError" timeout="2000" color="error">
			An error occured.
			<template v-slot:action="{ attrs }">
				<v-btn text v-bind="attrs" @click="setShowDownloadError(false)">Close</v-btn>
			</template>
		</v-snackbar>

		<generic-popup
			:showDialog="methodologyDownloadPopup"
			:contentComponent="'MethodologyDownloadPopup'"
			@popupDone="methodologyDownloadDone"
			@removePopup="methodologyDownloadPopup = false"
		/>
	</div>
</template>

<script>
import FileCard from '@/components/FileCard';
import GenericPopup from '@/components/GenericPopup';
import Ga from '@/mixins/ga';
import GaEvent from '@/constants/ga-event';
import EmptyCard from './EmptyCard.vue';
import FiltersButtonList from '@/components/FiltersButtonList';

const { mapState, mapGetters, mapMutations, mapActions } = require('vuex');
const api = require('@/utils/api');
const { filterUserRecentOrTrendingDocuments } = require('../utils/userRecentOrTrendingDocuments');

export default {
	name: 'FilesList',

	components: {
		FileCard,
		GenericPopup,
		EmptyCard,
		FiltersButtonList
	},

	mixins: [Ga],

	data () {
		return {
			sort: {
				value: null,
				items: [
					{ id: 'added', text: 'Recently added' },
					{ id: 'viewed', text: 'Recently viewed' },
					{ id: 'trending', text: 'Trending' }
				]
			},
			methodologyDocs: [],
			methodologyDownloadPopup: false
		};
	},

	computed: {
		...mapState({
			loadingFiles: (state) => state.contentLibrary.loadingFiles,
			files: (state) => state.contentLibrary.files,
			userRecentFiles: (state) => state.contentLibrary.userRecentFiles,
			trendingFiles: (state) => state.contentLibrary.trendingFiles,
			search: (state) => state.contentLibrary.search,
			showDownloadError: (state) => state.contentLibrary.showDownloadError
		}),
		...mapGetters('contentLibrary', [
			'filteredFiles'
		]),
		filteredAndSortedFiles () {
			if (this.sort.value?.id === 'viewed') {
				return filterUserRecentOrTrendingDocuments({
					userRecentOrTrendingdocuments: this.userRecentFiles,
					availableDocuments: this.filteredFiles,
					idField: 'fileId',
					limit: 30
				});
			}

			if (this.sort.value?.id === 'trending') {
				return filterUserRecentOrTrendingDocuments({
					userRecentOrTrendingdocuments: this.trendingFiles,
					availableDocuments: this.filteredFiles,
					idField: 'fileId',
					limit: 30
				});
			}

			return this.filteredFiles;
		}
	},

	async beforeMount () {
		this.setSearch('');
		this.setShowDownloadError(false);

		if (!this.files.length) {
			this.loadCollateralMethodologyFiles();
		}
		this.loadUserRecentCollateralMethodologyFiles();
		this.loadTrendingFiles();
	},

	methods: {
		...mapMutations('contentLibrary', [
			'setSearch',
			'setShowDownloadError'
		]),
		...mapActions('contentLibrary', [
			'loadCollateralMethodologyFiles',
			'loadUserRecentCollateralMethodologyFiles',
			'loadTrendingFiles',
			'storeCollateralAnalyticsInformations',
			'downloadFile',
			'onSelectPreview',
			'onSelectExport'
		]),

		async onSelectExportFromContentLibrary (file) {
			this.sendGaExportEvent(file.type.trim().toLowerCase() === 'collateral' ? GaEvent.exportCollateral : GaEvent.exportMethodology, file.name);

			this.onSelectExport(file);

			// disgusting right but it is the ticket https://gitlab.com/ipsos-facebook/ipsos-fb-main/-/issues/328
			const fileLimitationMSME = this.filteredFiles.find((item) => item.subtype === 'Methodology: Limitations Internal');
			if (fileLimitationMSME && file.subtype !== 'Methodology: Limitations Internal') {
				this.downloadFile(fileLimitationMSME);
			}

			if (file.type.trim().toLowerCase() === 'collateral') {
				await this.storeCollateralAnalyticsInformations(file);

				this.methodologyDocs = await api.loadFiles(`/api/v1/surveys/${file.surveyId}/methodology`);
				if (this.methodologyDocs.length) {
					this.methodologyDownloadPopup = true;
				}
			}
		},
		methodologyDownloadDone (toDownload) {
			this.methodologyDownloadPopup = false;
			if (!toDownload) return;

			for (const file of this.methodologyDocs) {
				this.downloadFile(file);
			}
		},
		onChangeSort () {
			if (this.sort.value?.id === 'viewed') {
				this.loadUserRecentCollateralMethodologyFiles();
			} else if (this.sort.value?.id === 'trending') {
				this.loadTrendingFiles();
			}
		}
	}
};
</script>

<style lang="scss">
@import "@/scss/_colors.scss";

.files-list {

	&-actions {
		display: flex;
		align-items: center;

    &-search {
      max-width: 300px;

      .v-input__icon--clear button {
        background-color: transparent !important;
        border: none !important;

        &:hover {
          border: none !important;
        }
      }

      .v-text-field {
        input {
          color: $meta-gray !important;
        }
      }
    }

    &-title {
      color: $meta-gray;
    }

    .filters-button {
      color: $meta-gray;
      font-size: inherit;
    }
  }
}
</style>
